import React from 'react'
import { View, Text, StyleSheet } from '@react-pdf/renderer'
import { useSelector } from 'react-redux'
import { priceFormat } from '../../../../utility/tools';


const styles = StyleSheet.create({
    section: {
        width: '100%',
        fontFamily: 'Raleway',
        fontSize: 11,
        lineHeight: 1.4,
        marginBottom: 20
    },
    categories: {
        width: '100%',
        fontWeight: 700,
        textAlign: 'center'
    },
    title: {
        fontWeight: 700,
        width: '30%'
    },  
    totalWH: {
        width: '15%'
    },  
    totalWF: {
        width: '15%'
    },  
    totalWT: {
        width: '40%',
        textAlign: 'right'
    },    
    header: {
        fontWeight: 700,
        paddingTop: 5,
        paddingBottom: 5,
        borderBottom: '1px solid #666666',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
    },
    column: {
        paddingTop: 5,
        paddingBottom: 5,
        borderBottom: '1px solid #666666',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
    }
});

const TechniciansWorkPriceTable = () => {
    const { calculatedTotal } = useSelector((state) => state.calculator)

    return (
        <View style={styles.section}>
            <View style={styles.header}>
                <Text style={styles.title}></Text>
                <Text style={styles.totalWH}>összes munkaóra</Text>
                <Text style={styles.totalWF}>óradíj</Text>
                <Text style={styles.totalWT}>összes munkadíj</Text>
            </View>
            <View style={styles.column}>
                <Text style={styles.title}>Technikus</Text>
                <Text style={styles.totalWH}>{calculatedTotal['Technikus']['Munkadíj']['összes munkaóra']} óra</Text>
                <Text style={styles.totalWF}>{priceFormat({price: calculatedTotal['Technikus']['Munkadíj']['óradíj'], lang: 'hu-HU', currency: 'HUF'})}</Text>
                <Text style={styles.totalWT}>{priceFormat({price: calculatedTotal['Technikus']['Munkadíj']['összes munkadíj'], lang: 'hu-HU', currency: 'HUF'})}</Text>
            </View>
            <View style={styles.column}>
                <Text style={styles.title}>Segédtechnikus</Text>
                <Text style={styles.totalWH}>{calculatedTotal['Segédtechnikus']['Munkadíj']['összes munkaóra']} óra</Text>
                <Text style={styles.totalWF}>{priceFormat({price: calculatedTotal['Segédtechnikus']['Munkadíj']['óradíj'], lang: 'hu-HU', currency: 'HUF'})}</Text>
                <Text style={styles.totalWT}>{priceFormat({price: calculatedTotal['Segédtechnikus']['Munkadíj']['összes munkadíj'], lang: 'hu-HU', currency: 'HUF'})}</Text>
            </View>
            <View style={styles.column}>
                <Text style={styles.title}>Szoftvertechnikus</Text>
                <Text style={styles.totalWH}>{calculatedTotal['Szoftvertechnikus']['Munkadíj']['összes munkaóra']} óra</Text>
                <Text style={styles.totalWF}>{priceFormat({price: calculatedTotal['Szoftvertechnikus']['Munkadíj']['óradíj'], lang: 'hu-HU', currency: 'HUF'})}</Text>
                <Text style={styles.totalWT}>{priceFormat({price: calculatedTotal['Szoftvertechnikus']['Munkadíj']['összes munkadíj'], lang: 'hu-HU', currency: 'HUF'})}</Text>
            </View>
        </View>
    )
}

export default TechniciansWorkPriceTable