import React from 'react'
import { useSelector } from 'react-redux'
import OfferItem from './OfferItem'

const OfferList = () => {
    const { offerList } = useSelector((state) => state.calculator)

    return (
        <div className="offer-list-container">
        {Object.keys(offerList).map((id) => {
            return <OfferItem key={id} offerItem={offerList[id]} />
})}
        </div>
    )
}

export default OfferList