import React from 'react'
import { useSelector } from 'react-redux'
import Installation from './Installation'

const InstallationList = () => {
  const { queryList } = useSelector((state) => state.calculator)

  return (
    <div className='installation-list-scroll'>
      <div className="installation-list-container">
        {queryList.map((installation) => (
            <Installation key={installation.id} installation={installation} />
        ))}
      </div>
    </div>
  )
}

export default InstallationList