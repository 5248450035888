import React from 'react'
import Sales from './Sales'
import Address from './Address'

const BasicInfo = () => {
  return (
    <div className='basic-info'>
        <Sales />
        <Address />
    </div>
  )
}

export default BasicInfo