import React from 'react'
import SearchForm from './SearchForm'
import InstallationList from './InstallationList'

const CalculatorSelect = () => {
    return (
    <div className="calculator-select-component">
        <div className="form-area select-column">
            <form>
                <SearchForm />
            </form>
        </div>
        <InstallationList />
    </div>
    )
}

export default CalculatorSelect