import React from 'react'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

const Pending = () => {
    const { pending } = useSelector((state) => state.calculator)

    return (
        <>
        {pending && <span className="loading"><FontAwesomeIcon icon={faSpinner} /></span>}
        </>
    )
}

export default Pending