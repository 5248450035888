import React from 'react'
import OfferList from './OfferList'



let timer

const CalculatorOffer = () => {
    return (
        <div className="main-column">
            <OfferList />
        </div>
    )
}

export default CalculatorOffer