import React from 'react'
import { View, Text, StyleSheet } from '@react-pdf/renderer'
import { useSelector } from 'react-redux';


const styles = StyleSheet.create({
    section: {
        width: '100%',
        fontFamily: 'Raleway',
        fontSize: 11,
        lineHeight: 1.4,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'flex-start',
        marginBottom: 20
    },
    header: {
        fontWeight: 700
    }
});

const TechniciansBasicData = () => {
    const { calculatedTotal } = useSelector((state) => state.calculator)

    return (
        <View style={styles.section}>
            {Object.keys(calculatedTotal).map((tech, i) => {
                return (
                <View style={styles.column} key={i}>
                    <Text style={styles.header}>{tech}</Text>
                    {Object.keys(calculatedTotal[tech]['Részletek']).map((data, k) => {
                        return (<Text key={k}>{data}: {calculatedTotal[tech]['Részletek'][data]} óra</Text>)
                    })}
                </View>
                )
            })}
        </View>
    )
}

export default TechniciansBasicData