import React from 'react'
import { View, Image, StyleSheet } from '@react-pdf/renderer'
import Logo from '../../../../img/anest_service_logo.png'
import CompanyInfo from './CompanyInfo'

const styles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottom: '1px solid #999',
        paddingBottom: 5
    },
    logo: {
        width: 'auto',
        height: 110
    }
  });

const PageHeader = () => {
  return (
    <View style={styles.header}>
        <Image style={styles.logo} src={Logo} />
        <CompanyInfo />
    </View>
  )
}

export default PageHeader