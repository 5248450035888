import axios from 'axios'

const fetchRouteData = async ({destination}) => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_SECRET_URL}/google/googledirection`, {
        params: {
            destination
        }
    })
    
    return res
}

export const googleDirectionApi = {
    fetchRouteData
}