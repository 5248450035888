import { createSlice, createAction } from '@reduxjs/toolkit'

export const setModal = createAction('modal/open', function prepare (newState) {
    return { payload: newState }
})

export const modalSlice = createSlice({
    name: 'modals',
    initialState: { state: null },
    reducers: {},
    extraReducers: {
        [setModal]: (state, { payload }) => {
            state.state = payload
        }
    }
})

export default modalSlice.reducer