const setBasicLaborFee = function ({item, total}) {
    total['Technikus']['Részletek']['Összeszerelési idő'] += item['assembling time'] || 0
    total['Technikus']['Részletek']['Képzési idő'] += item['training time'] || 0
    total['Technikus']['Részletek']['Funkcionális tesztelés'] += item['function test time'] || 0
    
    total['Segédtechnikus']['Részletek']['Munkaóra'] += item['second engineer time'] || 0
    
    total['Szoftvertechnikus']['Részletek']['Online óra'] += (item['software operation type'] === 'online') ? item['software training time'] : 0
    total['Szoftvertechnikus']['Részletek']['Offline óra'] += (item['software operation type'] === 'offline') ? item['software training time'] : 0

    return total
}

const calculations = function ({total, price, address}) {
    const distanceKMDay = Math.floor(2 * (address.distance / 1000)) //Utazott km egy nap (oda-vissza)
    const rawDurationHDay = Math.round(2 * (address.duration / 3600)) //Utazási idő 1 nap (oda-vissza)
    const maxDurationHDay = [rawDurationHDay, 1]
    const durationHDay = Math.max(...maxDurationHDay) //Legalább 1 óra a napi utazás

    //Technikus díjjazása
    const technicianLaborFee = price['labor fee']['type 1'] //Technikus óradíjja
    const technicianTotalTime = Math.ceil(total['Technikus']['Részletek']['Összeszerelési idő'] + total['Technikus']['Részletek']['Képzési idő'] + total['Technikus']['Részletek']['Funkcionális tesztelés']) //Össz munkaóra
    const isTechnicianAcc = (durationHDay > 3 && technicianTotalTime + durationHDay > 8) //Ott alvással, ha az oda-vissza út több, mint 3 óra, ÉS a munakidő + utazási idő több, mint 8 óra (1 munkanap)
    const isTechnicianWeekend = (isTechnicianAcc && Math.ceil((technicianTotalTime / 8) - durationHDay) > 3) //Ha több, mint 3 nap a munka ott alvással, akkor hétvége is
    //console.log('is tech weekend', isTechnicianWeekend);
    const technicianTravelDays = (isTechnicianAcc) 
    ? 
        (isTechnicianWeekend) ? 2 : 1 //ha hétvége is van, akkor 2 utazási nap, ha nincs akkor csak egy
    : Math.ceil(technicianTotalTime / (8 - durationHDay))
    const technicianWorkDays = Math.ceil(((technicianTravelDays * durationHDay) + technicianTotalTime) / 8)

    total['Technikus']['Munkadíj']['összes nap'] = technicianWorkDays
    total['Technikus']['Munkadíj']['összes munkaóra'] = technicianTotalTime
    total['Technikus']['Munkadíj']['óradíj'] = technicianLaborFee
    total['Technikus']['Munkadíj']['összes munkadíj'] = technicianTotalTime * technicianLaborFee

    total['Technikus']['Utazási költség']['nap'] = technicianTravelDays
    total['Technikus']['Utazási költség']['össz km'] = technicianTravelDays * distanceKMDay
    total['Technikus']['Utazási költség']['díj / km'] = price['travel fee']['type 1']['km']
    total['Technikus']['Utazási költség']['össz km díj'] = total['Technikus']['Utazási költség']['össz km'] * total['Technikus']['Utazási költség']['díj / km']
    total['Technikus']['Utazási költség']['össz idő'] = technicianTravelDays * durationHDay
    total['Technikus']['Utazási költség']['díj / óra'] = price['travel fee']['type 1']['hour']
    total['Technikus']['Utazási költség']['össz óradíj'] = total['Technikus']['Utazási költség']['össz idő'] * total['Technikus']['Utazási költség']['díj / óra']
    total['Technikus']['Utazási költség']['szállásolás szükséges'] = (isTechnicianAcc) ? 'igen' : 'nem'
    total['Technikus']['Utazási költség']['teljes utazási költség'] = total['Technikus']['Utazási költség']['össz km díj'] + total['Technikus']['Utazási költség']['össz óradíj']
    
    total['Technikus']['Technikus teljes díjja'] = total['Technikus']['Munkadíj']['összes munkadíj'] + total['Technikus']['Utazási költség']['teljes utazási költség']

    //Segédtechnikus díjjazása
    const supportTechnicianLaborFee = price['labor fee']['type 2']
    const supportTechnicianTotalTime = Math.ceil(total['Segédtechnikus']['Részletek']['Munkaóra'])
    const isSupportTechnicianAcc = (durationHDay > 3 && supportTechnicianTotalTime + durationHDay > 8)
    const isSupportTechnicianWeekend = (isSupportTechnicianAcc && Math.ceil((supportTechnicianTotalTime / 8) - durationHDay) > 3) //Ha több, mint 3 nap a munka ott alvással, akkor hétvége is
    const supportTechnicianTravelDays = (isSupportTechnicianAcc) 
    ? 
        (isSupportTechnicianWeekend) ? 2 : 1 //ha hétvége is van, akkor 2 utazási nap, ha nincs akkor csak egy
    : Math.ceil(supportTechnicianTotalTime / (8 - durationHDay))
    //const supportTechnicianTravelDays = (isSupportTechnicianAcc) ? 2 : Math.ceil(supportTechnicianTotalTime / (8 - durationHDay))
    const supportTechnicianWorkDays = Math.ceil(((supportTechnicianTravelDays * durationHDay) + supportTechnicianTotalTime) / 8)

    total['Segédtechnikus']['Munkadíj']['összes nap'] = supportTechnicianWorkDays
    total['Segédtechnikus']['Munkadíj']['összes munkaóra'] = supportTechnicianTotalTime
    total['Segédtechnikus']['Munkadíj']['óradíj'] = supportTechnicianLaborFee
    total['Segédtechnikus']['Munkadíj']['összes munkadíj'] = supportTechnicianTotalTime * supportTechnicianLaborFee

    total['Segédtechnikus']['Utazási költség']['nap'] = supportTechnicianTravelDays
    total['Segédtechnikus']['Utazási költség']['össz km'] = supportTechnicianTravelDays * distanceKMDay
    total['Segédtechnikus']['Utazási költség']['díj / km'] = price['travel fee']['type 2']['km']
    total['Segédtechnikus']['Utazási költség']['össz km díj'] = total['Segédtechnikus']['Utazási költség']['össz km'] * total['Segédtechnikus']['Utazási költség']['díj / km']
    total['Segédtechnikus']['Utazási költség']['össz idő'] = supportTechnicianTravelDays * durationHDay
    total['Segédtechnikus']['Utazási költség']['díj / óra'] = price['travel fee']['type 2']['hour']
    total['Segédtechnikus']['Utazási költség']['össz óradíj'] = total['Segédtechnikus']['Utazási költség']['össz idő'] * total['Segédtechnikus']['Utazási költség']['díj / óra']
    total['Segédtechnikus']['Utazási költség']['szállásolás szükséges'] = (isSupportTechnicianAcc) ? 'igen' : 'nem'
    total['Segédtechnikus']['Utazási költség']['teljes utazási költség'] = total['Segédtechnikus']['Utazási költség']['össz km díj'] + total['Segédtechnikus']['Utazási költség']['össz óradíj']
    
    total['Segédtechnikus']['Segédtechnikus teljes díjja'] = total['Segédtechnikus']['Munkadíj']['összes munkadíj'] + total['Segédtechnikus']['Utazási költség']['teljes utazási költség']

    //Szoftvertechnikus díjjazása
    const softwareTechnicianLaborFee = price['labor fee']['type 3']
    const softwareTechnicianTotalTime = Math.ceil(total['Szoftvertechnikus']['Részletek']['Online óra'] + total['Szoftvertechnikus']['Részletek']['Offline óra'])
    const softwareTechnicianTotalTimeToTravel = Math.ceil(total['Szoftvertechnikus']['Részletek']['Offline óra'])
    const softwareTechnicianTotalTimeOnline = Math.ceil(total['Szoftvertechnikus']['Részletek']['Online óra'])
    const isSoftwareTechnicianAcc = (durationHDay > 3 && softwareTechnicianTotalTimeToTravel + durationHDay > 8)
    const isSoftwareTechnicianWeekend = (isSoftwareTechnicianAcc && Math.ceil((softwareTechnicianTotalTime / 8) - durationHDay) > 3) //Ha több, mint 3 nap a munka ott alvással, akkor hétvége is
    const softwareTechnicianTravelDays = (softwareTechnicianTotalTimeToTravel < 1) ? 0 : (isSoftwareTechnicianAcc) 
    ? 
        (isSoftwareTechnicianWeekend) ? 2 : 1 //ha hétvége is van, akkor 2 utazási nap, ha nincs akkor csak egy
    : Math.ceil(softwareTechnicianTotalTime / (8 - durationHDay))
    //const softwareTechnicianTravelDays = (isSoftwareTechnicianAcc) ? 2 : Math.ceil(softwareTechnicianTotalTimeToTravel / (8 - durationHDay))
    const softwareTechnicianWorkDays = Math.ceil(softwareTechnicianTotalTimeOnline / 8) + Math.ceil(((softwareTechnicianTravelDays * durationHDay) + softwareTechnicianTotalTimeToTravel) / 8)

    total['Szoftvertechnikus']['Munkadíj']['összes nap'] = softwareTechnicianWorkDays
    total['Szoftvertechnikus']['Munkadíj']['összes munkaóra'] = softwareTechnicianTotalTime
    total['Szoftvertechnikus']['Munkadíj']['óradíj'] = softwareTechnicianLaborFee
    total['Szoftvertechnikus']['Munkadíj']['összes munkadíj'] = softwareTechnicianTotalTime * softwareTechnicianLaborFee

    total['Szoftvertechnikus']['Utazási költség']['nap'] = softwareTechnicianTravelDays
    total['Szoftvertechnikus']['Utazási költség']['össz km'] = softwareTechnicianTravelDays * distanceKMDay
    total['Szoftvertechnikus']['Utazási költség']['díj / km'] = price['travel fee']['type 3']['km']
    total['Szoftvertechnikus']['Utazási költség']['össz km díj'] = total['Szoftvertechnikus']['Utazási költség']['össz km'] * total['Szoftvertechnikus']['Utazási költség']['díj / km']
    total['Szoftvertechnikus']['Utazási költség']['össz idő'] = softwareTechnicianTravelDays * durationHDay
    total['Szoftvertechnikus']['Utazási költség']['díj / óra'] = price['travel fee']['type 3']['hour']
    total['Szoftvertechnikus']['Utazási költség']['össz óradíj'] = total['Szoftvertechnikus']['Utazási költség']['össz idő'] * total['Szoftvertechnikus']['Utazási költség']['díj / óra']
    total['Szoftvertechnikus']['Utazási költség']['szállásolás szükséges'] = (isSoftwareTechnicianAcc) ? 'igen' : 'nem'
    total['Szoftvertechnikus']['Utazási költség']['teljes utazási költség'] = total['Szoftvertechnikus']['Utazási költség']['össz km díj'] + total['Szoftvertechnikus']['Utazási költség']['össz óradíj']
    
    total['Szoftvertechnikus']['Szoftvertechnikus teljes díjja'] = total['Szoftvertechnikus']['Munkadíj']['összes munkadíj'] + total['Szoftvertechnikus']['Utazási költség']['teljes utazási költség']

    return total
}

export const totalCalculation = ({offerList, price, address}) => {
    let total = {
        'Technikus': {
            'Részletek': {
                'Összeszerelési idő': 0,
                'Képzési idő': 0,
                'Funkcionális tesztelés': 0
            },
            'Munkadíj': {
                'összes nap': 0,
                'összes munkaóra': 0,
                'óradíj': 0,
                'összes munkadíj': 0
            },
            'Utazási költség': {
                'nap': 0,
                'össz km': 0,
                'díj / km': 0,
                'össz km díj': 0,
                'össz idő': 0,
                'díj / óra': 0,
                'össz óradíj': 0,
                'szállásolás szükséges': 'nem',
                'teljes utazási költség': 0
            },
            'Technikus teljes díjja': 0
        },
        'Segédtechnikus': {
            'Részletek': {
                'Munkaóra': 0
            },
            'Munkadíj': {
                'összes nap': 0,
                'összes munkaóra': 0,
                'óradíj': 0,
                'összes munkadíj': 0
            },
            'Utazási költség': {
                'nap': 0,
                'össz km': 0,
                'díj / km': 0,
                'össz idő': 0,
                'óradíj': 0,
                'szállásolás szükséges': 'nem',
                'teljes utazási költség': 0
            },
            'Segédtechnikus teljes díjja': 0
        },
        'Szoftvertechnikus': {
            'Részletek': {
                'Online óra': 0,
                'Offline óra': 0
            },
            'Munkadíj': {
                'összes nap': 0,
                'összes munkaóra': 0,
                'óradíj': 0,
                'összes munkadíj': 0
            },
            'Utazási költség': {
                'nap': 0,
                'össz km': 0,
                'díj / km': 0,
                'össz idő': 0,
                'óradíj': 0,
                'szállásolás szükséges': 'nem',
                'teljes utazási költség': 0
            },
            'Szoftvertechnikus teljes díjja': 0
        }
    }

    //Collect basic data
    Object.keys(offerList).forEach(offerId => {
        const offer = offerList[offerId]

        total = {...total, ...setBasicLaborFee({total, item: offer})}

        Object.keys(offer.options).forEach(optionId => {
            const option = offer.options[optionId]

            total = {...total, ...setBasicLaborFee({total, item: option})}
        })
    })

    //Calculate
    total = {...total, ...calculations({total, price, address})}

    return total
}