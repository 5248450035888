import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const Address = () => {
  const { address } = useSelector((state) => state.calculator)
  const [addressData, setAddressData] = useState(address.full)

  useEffect(() => {
      setAddressData(address.full)
  }, [address.full])

  return (
      <div className='data'>Address: <span>{addressData}</span></div>
  )
}

export default Address