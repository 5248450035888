import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import { addToOffer, addOption } from '../redux/installationSlice'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons"

const Installation = ( {installation} ) => {
    const dispatch = useDispatch()
    const { calculator } = useSelector((state) => state)

    //TODO: Ha van version, akkor addToOffer helyett addOption legyen

    const addInstallation = () => {
        if (!calculator.version) {
            dispatch(addToOffer(installation))
        } else {
            dispatch(addOption({offerId: calculator.version, installation}))
        }
    }

    return (
        <div className="installation-item" id={installation.id}>
            <div className="add" onClick={() => addInstallation()}><FontAwesomeIcon icon={faCirclePlus} /></div>
            <div className="content">
                <header>
                    <div className="first-row">
                        <div className="model">{installation.model}</div>
                        <div className="code">{installation.code}</div>
                    </div>
                    <div className="second-row">
                        <div className="description">{installation.description}</div>
                    </div>
                </header>
            </div>
        </div>
    )
}

export default Installation