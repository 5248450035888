import axios from 'axios'

const fetchAllInstallations = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_SECRET_URL}/calculator/installations`)

    return res.data
}

const fetchInstallationsByQuery = async (query) => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_SECRET_URL}/calculator/installations`, {
        params: {
            query
        }
    })

    return res.data
}

export const installationApi = {
    fetchAllInstallations,
    fetchInstallationsByQuery
}