import React from 'react'
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPenToSquare, faSquareMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons"
import { setModal } from '../../../redux/modalSlice'
import { setVersion } from '../redux/installationSlice'
import { removeOffer } from '../redux/installationSlice'
import OptionItem from './OptionItem'

const OfferItem = ( {offerItem} ) => {
    const dispatch = useDispatch()
    const { calculator } = useSelector((state) => state)
    const [itemId, setItemId] = useState(offerItem.id)
    const [action, setAction] = useState(null)

    const editOffer = (id) => {
        setAction('edit')
        dispatch(setVersion(id))
    }

    const addOption = (id) => {
        setAction('addOption')
        dispatch(setVersion(id))
    }

    const deleteOffer = (id) => {
        dispatch(removeOffer(id))
    }

    useEffect(() => {
        if (calculator.version && action === 'edit') {
            dispatch(setModal('editOffer'))
            setAction(null)
        }
        if (calculator.version && action === 'addOption') {
            dispatch(setModal('addOption'))
            setAction(null)
        }
    }, [calculator.version])

    return (
        <>
        <div className="installation-item" id={offerItem.id}>
            <div className="installation-actions action">
                <button onClick={() => editOffer(itemId)} title="Edit Installation" className='edit'><FontAwesomeIcon icon={faPenToSquare} /></button>
                <button onClick={() => deleteOffer(itemId)} title="Delete Installation" className='warning'><FontAwesomeIcon icon={faSquareMinus} /></button>
            </div>
            <div className="content">
                <header>
                    <div className="first-row">
                        <div className="model">{offerItem.model}</div>
                        <div className="code">{offerItem.code}</div>
                    </div>
                    <div className="second-row">
                        <div className="description">{offerItem.description}</div>
                    </div>
                </header>
                <div className="installation-options">
                    <div className="title">Options</div>
                    <div className="options-list">
                        {Object.keys(offerItem.options).map(optionId => {
                            const option = offerItem.options[optionId]
                            return (<OptionItem key={option.id} offerId={itemId} option={option} />)
                            }
                        )}
                    </div>
                    <div className="action option-add">
                        <button onClick={() => addOption(itemId)} className="company"><FontAwesomeIcon icon={faCirclePlus} /> Add Option</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default OfferItem