import React from 'react'
import { View, Text, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
    option: {
        width: '100%',
        fontFamily: 'Raleway',
        fontSize: 11,
        lineHeight: 1.4,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        borderLeft: '1px solid #cccccc'
    },
    marker: {
        width: '5%',
        textAlign: 'center'
    },
    code: {
        width: '28%',
        fontWeight: 700
    },
    description: {
        width: '67%'
    }
})

const Option = ({option}) => {
    return (
        <View style={styles.option} wrap={false}>
            <Text style={styles.marker}>◾</Text>
            <Text style={styles.code}>{option.code}</Text>
            <Text style={styles.description}>{option.description}</Text>
        </View>
    )
}

export default Option