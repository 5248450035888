import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import BasicMainMenu from './BasicMainMenu'
import Logo from "../../img/anest_group_white.svg"
import "./BasicHeader.css"

const BasicHeader = () => {
  return (
    <>
        <div className="main-header">
            <div className="logo">
                <Link to="/tools">
                    <img src={Logo} alt="ANEST logó" />
                </Link>
            </div>
            <div className="title">ANES<b>T</b>ools</div>
            <div className="hamburger-menu">
                <span><FontAwesomeIcon icon={faBars} /></span>
            </div>
        </div>
        <BasicMainMenu />
    </>
  )
}

export default BasicHeader