import React from 'react'
import { View, Text, StyleSheet } from '@react-pdf/renderer'
import { useSelector } from 'react-redux';

const styles = StyleSheet.create({
    container: {
        width: '100%',
        height: 44,
        marginTop: 40,
        marginBottom: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        borderBottom: '1px solid #666666'
    },
    basicInfo: {
        textAlign: 'left'
    },
    em: {
        fontWeight: 700
    }
});

const BasicInfo = () => {
    const { sales, address } = useSelector((state) => state.calculator)

    return (
        <View style={styles.container}>
            <View style={styles.basicInfo}>
                <Text style={styles.em}>Üzletkötő</Text>
                <Text>{sales}</Text>
            </View>
            <View style={styles.basicInfo}>
                <Text style={styles.em}>Beüzemelési cím</Text>
                <Text>{address.full}</Text>
            </View>
            <View style={styles.basicInfo}>
                <Text style={styles.em}>Távolság, utazási idő</Text>
                <Text>{Math.ceil(address.distance / 1000)} km | {Math.round((address.duration / 3600) * 10) / 10} óra</Text>
            </View>
        </View>
    )
}

export default BasicInfo