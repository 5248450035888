import React from 'react'
import { View, Text, StyleSheet } from '@react-pdf/renderer'
import { useSelector } from 'react-redux'
import { priceFormat } from '../../../../utility/tools'


const styles = StyleSheet.create({
    section: {
        width: 555,
        fontFamily: 'Raleway',
        fontSize: 11,
        lineHeight: 1.4,
        marginBottom: 20
    },
    title: {
        fontWeight: 700,
        width: 100,
        flexShrink: 0,
        flexGrow: 0
    },   
    totalTD: {
        width: 55,
        textAlign: 'center',
        flexShrink: 0,
        flexGrow: 0
    },   
    totalTTKM: {
        width: 72,
        textAlign: 'center',
        flexShrink: 0,
        flexGrow: 0
    },  
    totalTFKM: {
        width: 55,
        flexShrink: 0,
        flexGrow: 0
    },  
    totalTTH: {
        width: 55,
        textAlign: 'center',
        flexShrink: 0,
        flexGrow: 0
    },  
    totalTFH: {
        width: 66,
        textAlign: 'center',
        flexShrink: 0,
        flexGrow: 0
    },  
    totalACC: {
        width: 66,
        textAlign: 'center',
        flexShrink: 0,
        flexGrow: 0
    },  
    totalTT: {
        width: 86,
        textAlign: 'right',
        flexShrink: 0,
        flexGrow: 0
    },  
    header: {
        width: 555,
        fontWeight: 700,
        paddingTop: 5,
        paddingBottom: 5,
        borderBottom: '1px solid #666666',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
    },
    column: {
        width: 555,
        paddingTop: 5,
        paddingBottom: 5,
        borderBottom: '1px solid #666666',
        flexDirection: 'row',
        justifyContent:'flex-start',
        alignContent: 'flex-start',
    }
})

const TechniciansTravelPriceTable = () => {
    const { calculatedTotal } = useSelector((state) => state.calculator)

    return (
        <View style={styles.section}>
            <View style={styles.header}>
                <Text style={styles.title}></Text>
                <Text style={styles.totalTD}>utazási napok száma</Text>
                <Text style={styles.totalTTKM}>össz km</Text>
                <Text style={styles.totalTFKM}>Ft/km</Text>
                <Text style={styles.totalTTH}>össz utazott óra</Text>
                <Text style={styles.totalTFH}>Ft/óra</Text>
                <Text style={styles.totalACC}>szállásolás</Text>
                <Text style={styles.totalTT}>teljes utazási költség</Text>
            </View>
            <View style={styles.column}>
                <Text style={styles.title}>Technikus</Text>
                <Text style={styles.totalTD}>{calculatedTotal['Technikus']['Utazási költség']['nap']}</Text>
                <Text style={styles.totalTTKM}>{calculatedTotal['Technikus']['Utazási költség']['össz km']} km</Text>
                <Text style={styles.totalTFKM}>{priceFormat({price: calculatedTotal['Technikus']['Utazási költség']['díj / km'], lang: 'hu-HU', currency: 'HUF'})}</Text>
                <Text style={styles.totalTTH}>{calculatedTotal['Technikus']['Utazási költség']['össz idő']} óra</Text>
                <Text style={styles.totalTFH}>{priceFormat({price: calculatedTotal['Technikus']['Utazási költség']['díj / óra'], lang: 'hu-HU', currency: 'HUF'})}</Text>
                <Text style={styles.totalACC}>{calculatedTotal['Technikus']['Utazási költség']['szállásolás szükséges']}</Text>
                <Text style={styles.totalTT}>{priceFormat({price: calculatedTotal['Technikus']['Utazási költség']['teljes utazási költség'], lang: 'hu-HU', currency: 'HUF'})}</Text>
            </View>
            <View style={styles.column}>
                <Text style={styles.title}>Segédtechnikus</Text>
                <Text style={styles.totalTD}>{calculatedTotal['Segédtechnikus']['Utazási költség']['nap']}</Text>
                <Text style={styles.totalTTKM}>{calculatedTotal['Segédtechnikus']['Utazási költség']['össz km']} km</Text>
                <Text style={styles.totalTFKM}>{priceFormat({price: calculatedTotal['Segédtechnikus']['Utazási költség']['díj / km'], lang: 'hu-HU', currency: 'HUF'})}</Text>
                <Text style={styles.totalTTH}>{calculatedTotal['Segédtechnikus']['Utazási költség']['össz idő']} óra</Text>
                <Text style={styles.totalTFH}>{priceFormat({price: calculatedTotal['Segédtechnikus']['Utazási költség']['díj / óra'], lang: 'hu-HU', currency: 'HUF'})}</Text>
                <Text style={styles.totalACC}>{calculatedTotal['Segédtechnikus']['Utazási költség']['szállásolás szükséges']}</Text>
                <Text style={styles.totalTT}>{priceFormat({price: calculatedTotal['Segédtechnikus']['Utazási költség']['teljes utazási költség'], lang: 'hu-HU', currency: 'HUF'})}</Text>
            </View>
            <View style={styles.column}>
                <Text style={styles.title}>Szoftvertechnikus</Text>
                <Text style={styles.totalTD}>{calculatedTotal['Szoftvertechnikus']['Utazási költség']['nap']}</Text>
                <Text style={styles.totalTTKM}>{calculatedTotal['Szoftvertechnikus']['Utazási költség']['össz km']} km</Text>
                <Text style={styles.totalTFKM}>{priceFormat({price: calculatedTotal['Szoftvertechnikus']['Utazási költség']['díj / km'], lang: 'hu-HU', currency: 'HUF'})}</Text>
                <Text style={styles.totalTTH}>{calculatedTotal['Szoftvertechnikus']['Utazási költség']['össz idő']} óra</Text>
                <Text style={styles.totalTFH}>{priceFormat({price: calculatedTotal['Szoftvertechnikus']['Utazási költség']['díj / óra'], lang: 'hu-HU', currency: 'HUF'})}</Text>
                <Text style={styles.totalACC}>{calculatedTotal['Szoftvertechnikus']['Utazási költség']['szállásolás szükséges']}</Text>
                <Text style={styles.totalTT}>{priceFormat({price: calculatedTotal['Szoftvertechnikus']['Utazási költség']['teljes utazási költség'], lang: 'hu-HU', currency: 'HUF'})}</Text>
            </View>
        </View>
    )
}

export default TechniciansTravelPriceTable