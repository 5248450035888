import { createAsyncThunk, createSlice, createAction, nanoid } from '@reduxjs/toolkit'
import { installationApi } from '../api/installationApi'
import { priceApi } from '../api/priceApi'
import { googleDirectionApi } from '../../../api/googleDirectionApi'
import { totalCalculation } from '../actions/totalCalculation'

export const getAllInstallations = createAsyncThunk(
    'installations/getAll',
    async (thunkAPI) => {
        const response = await installationApi.fetchAllInstallations()
        return response
    }
)

export const getInstallationsByQuery = createAsyncThunk(
    'installations/getByQuery',
    async (query, thunkAPI) => {
        const response = await installationApi.fetchInstallationsByQuery(query)
        return response?.map(installation => ({...installation, id: installation._id}))
    }
)

export const emptyInstallations = createAction('installations/empty')

export const addToOffer = createAction('offers/add', function prepare (installation) {
    const newId = nanoid()
    let payload = {
        [newId]: {
            ...installation,
            options: {},
            id: newId
        }
    }
    return { payload }
})

export const updateOffer = createAction('offer/update', function prepare (installation) {
    return { 
        payload: {
            [installation.id]: installation
        }
    }
})

export const removeOffer = createAction('offer/remove', function prepare (id) {
    return { payload: id }
})

export const addOption = createAction('option/add', function prepare ({offerId, installation}) {
    const newId = nanoid()
    let payload = {
        offerId,
        data: {
            [newId]: {
                ...installation,
                id: newId
            }
        }
    }
    return { payload }
})

export const updateOption = createAction('option/update', function prepare ({offerId, installation}) {
    return { 
        payload: {
            offerId,
            data: {
                [installation.id]: installation
            }
        }
    }
})

export const removeOption = createAction('option/remove', function prepare ({offerId, optionId}) {
    let payload = {
        offerId,
        optionId
    }
    return { payload }
})

export const setVersion = createAction('version/set', function prepare (id) {
    return { payload: id }
})

export const setOption = createAction('option/set', function prepare (id) {
    return { payload: id }
})

export const setAddress = createAsyncThunk(
    'address/set',
    async ({zip, city, address, setError, setTravelData}, thunkAPI) => {
        const destination = `${zip} ${city}, ${address}`
        const response = await googleDirectionApi.fetchRouteData({ destination })

        if (response.data.routes.length) {
            const payload = {
                zip,
                city,
                address,
                full: destination,
                distance: response.data.routes[0].legs[0].distance.value,
                duration: response.data.routes[0].legs[0].duration.value
            }

            setTravelData({...payload})
            setError(null)

            return payload
        } else {
            setTravelData(null)
            setError('Wrong address')
        }
    }
)

export const setSales = createAction('sale/set', function prepare (sales) {
    const salesName = (sales === 'null') ? null : sales
    return { payload: salesName }
})

export const getPrices = createAsyncThunk(
    'price/get',
    async (thunkAPI) => {
        const prices = await priceApi.getPriceTable()
        return { 'travel fee': prices['travel fee'], 'labor fee': prices['labor fee'] }
    }
)

export const calculate = createAction('calculate', function prepare ({offerList, price, address }) {
    const res = totalCalculation({offerList, price, address })

    return {payload: res}
})

export const installationSlice = createSlice({
    name: 'installations',
    initialState: {
        queryList: [],
        offerList: {},
        address: {},
        price: {},
        calculatedTotal: {},
        sales: null,
        pending: false,
        error: false,
        version: null,
        option: null
    },
    reducers: {},
    extraReducers: {
        [calculate]: (state, { payload }) => {
            state.calculatedTotal = payload
        },
        [setVersion]: (state, { payload }) => {
            state.version = payload
        },
        [setOption]: (state, { payload }) => {
            state.option = payload
        },
        [setSales]: (state, { payload }) => {
            state.sales = payload
        },
        [setAddress.pending]: (state) => {
            state.pending = true
            state.error = false
        },
        [setAddress.fulfilled]: (state, { payload }) => {
            state.address = { ...state.address, ...payload }
            state.pending = false
            state.error = false
        },
        [setAddress.rejected]: (state) => {
            state.pending = false
            state.error = true
        },
        [getPrices.pending]: (state) => {
            state.pending = true
            state.error = false
        },
        [getPrices.fulfilled]: (state, { payload }) => {
            state.price = { ...state.price, ...payload }
            state.pending = false
            state.error = false
        },
        [getPrices.rejected]: (state) => {
            state.pending = false
            state.error = true
        },
        [addToOffer]: (state, { payload }) => {
            state.offerList = {...state.offerList, ...payload}
        },
        [updateOffer]: (state, { payload }) => {
            state.offerList = {...state.offerList, ...payload}
        },
        [removeOffer]: (state, { payload }) => {
            delete state.offerList[payload]            
        },
        [addOption]: (state, { payload }) => {
            state.offerList[payload.offerId]['options'] = { ...state.offerList[payload.offerId]['options'], ...payload.data }
        },
        [updateOption]: (state, { payload }) => {
            state.offerList[payload.offerId]['options'] = { ...state.offerList[payload.offerId]['options'], ...payload.data }
        },
        [removeOption]: (state, { payload }) => {
            delete state.offerList[payload.offerId]['options'][payload.optionId]
        },
        [getAllInstallations.pending]: (state) => {
            state.pending = true
            state.error = false
        },
        [getAllInstallations.fulfilled]: (state, { payload }) => {
            state.pending = false
            state.error = false
            state.queryList = payload
        },
        [getAllInstallations.rejected]: (state) => {
            state.pending = false
            state.error = true
        },
        [getInstallationsByQuery.pending]: (state) => {
            state.pending = true
            state.error = false
        },
        [getInstallationsByQuery.fulfilled]: (state, { payload }) => {
            state.pending = false
            state.error = false
            state.queryList = payload
        },
        [getInstallationsByQuery.rejected]: (state) => {
            state.pending = false
            state.error = true
        },
        [emptyInstallations]: (state) => {
            state.queryList = []
        }
    }
})

export default installationSlice.reducer