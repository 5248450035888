import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const Sales = () => {
    const { sales } = useSelector((state) => state.calculator)
    const [salesName, setSalesName] = useState(sales)

    useEffect(() => {
        setSalesName(sales)
    }, [sales])

    return (
        <div className='data'>Sales: <span>{salesName}</span></div>
    )
}

export default Sales