import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateOffer, updateOption } from '../redux/installationSlice'
import { setModal } from '../../../redux/modalSlice'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons"

const FormEditOfferItem = () => {
    const [offerItem, setOfferItem] = useState({})
    const { calculator } = useSelector((state) => state)
    const dispatch = useDispatch()

    useEffect(() => {
        if (calculator.version && !calculator.option) {
            setOfferItem({...calculator.offerList[calculator.version]})
        } else if (calculator.version && calculator.option) {
            setOfferItem({...calculator.offerList[calculator.version]['options'][calculator.option]})
        }
    }, []) 
    
    const formChange = (e) => {
        setOfferItem({...offerItem, [e.target.id]: e.target.value})
    }

    const save = (e) => {
        e.preventDefault()

        if (calculator.version && !calculator.option) {
            dispatch(updateOffer(offerItem))
        } else if (calculator.version && calculator.option) {
            dispatch(updateOption({offerId: calculator.version, installation: offerItem}))
        }
        
        const close = setTimeout(()=> {
            clearTimeout(close)
            dispatch(setModal(null))
        }, 500)
    }

    return (
        <div className='form-area'>
            <form onSubmit={(e) => save(e)}>
                <div className="fix-data">Model: <span>{offerItem.model}</span></div>
                <div className="fix-data">Code: <span>{offerItem.code}</span></div>
                <div className="form-row">
                    <label htmlFor="description"></label>
                    <textarea name="description" id="description" value={offerItem.description} onChange={(e) => formChange(e)}></textarea>
                </div>
                <div className="form-row action">
                    <button className='edit'><FontAwesomeIcon icon={faFloppyDisk} /> Save</button>
                </div>
            </form>
        </div>
    )
}

export default FormEditOfferItem