import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { removeOption, setVersion, setOption } from '../redux/installationSlice'
import { setModal } from '../../../redux/modalSlice'
import { updateOption } from '../redux/installationSlice'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPenToSquare, faSquareMinus, faCar } from "@fortawesome/free-solid-svg-icons"
import { faViber } from "@fortawesome/free-brands-svg-icons"

const OptionItem = ({offerId, option}) => {
    const dispatch = useDispatch()
    const { calculator } = useSelector((state) => state)
    const [iDs, setIDs] = useState({offerId, optionId: option.id})
    const [origin, setOrigin] = useState(option)

    const editOption = () => {
        dispatch(setVersion(offerId))
        dispatch(setOption(option.id))
    }
    
    const deleteOption = () => {
        dispatch(removeOption({offerId: iDs.offerId, optionId: iDs.optionId}))
    }

    const setSoftwareType = (type) => {
        setOrigin({...origin, ['software operation type']: type})
    }

    useEffect(() => {
        if (calculator.version && calculator.option)
            dispatch(setModal('editOption'))
    }, [calculator.version, calculator.option])

    useEffect(() => {
        dispatch(updateOption({offerId, installation: origin}))
    }, [origin])

    return (
        <div className="option-item">
            <div className="code">{option.code}</div>
            <div className="description">{option.description}</div>
            <div className="action">
                {option['software training time'] > 0 
                    ? option['software operation type'] === 'online' 
                        ? (<button onClick={() => setSoftwareType('offline')} className='software-type'><FontAwesomeIcon icon={faViber} title="Online" /></button>)
                        : (<button onClick={() => setSoftwareType('online')} className='software-type'><FontAwesomeIcon icon={faCar} title="Offline" /></button>)
                    : ('')
                }
                <button onClick={() => editOption()} className='edit'><FontAwesomeIcon icon={faPenToSquare} title="Edit" /></button>
                <button onClick={() => deleteOption()} className='warning'><FontAwesomeIcon icon={faSquareMinus} title="Remove" /></button>
            </div>
        </div>
    )
}

export default OptionItem