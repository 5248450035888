import React from 'react'
import { Page, Document, StyleSheet, Font } from '@react-pdf/renderer'
import PageHeader from './PageHeader'
import BasicInfo from './BasicInfo'
import PageNumber from './PageNumber'
import LegalText from './LegalText'
import TechniciansBasicData from './TechniciansBasicData'
import TechniciansWorkPriceTable from './TechniciansWorkPriceTable'
import TotalPrice from './TotalPrice'
import InstallationList from './InstallationList'
import TechniciansTravelPriceTable from './TechniciansTravelPriceTable'
import EndRow from './EndRow'
import RalewayRegular from '../../../../fonts/Raleway-Regular.ttf'
import RalewayMedium from '../../../../fonts/Raleway-Medium.ttf'
import RalewayBold from '../../../../fonts/Raleway-Bold.ttf'

Font.register({ family: 'Raleway', fonts: [
  { src: RalewayRegular }, // font-style: normal, font-weight: normal
  { src: RalewayMedium, fontWeight: 500 }, // font-style: normal, font-weight: normal
  { src: RalewayBold, fontWeight: 700 }
]});

Font.registerHyphenationCallback(word => [word]);

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Raleway',
        fontSize: 11,
        paddingTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 10,
        lineHeight: 1.4,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        borderTop: '5px solid #03505a',
        borderBottom: '20px solid #03505a'
    },
    priceSection: {
      padding: 5,
      marginBottom: 40,
      border: '1px solid #999'
    }
  });

const DocumentPDFOffer = () => {
  return (
    <Document>
        <Page size="A4" style={styles.page}>
          <PageHeader />
          <BasicInfo />
          <TechniciansBasicData />
          <TechniciansWorkPriceTable />
          <TechniciansTravelPriceTable />
          <TotalPrice />
          <InstallationList />
          <LegalText />
          <EndRow />
          <PageNumber />
        </Page>
    </Document>
  )
}

export default DocumentPDFOffer