import axios from 'axios'

const getPriceTable = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_SECRET_URL}/calculator/price`)

    return res.data
}

export const priceApi = {
    getPriceTable
}