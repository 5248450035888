import React from 'react'
import { View, Text, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
    section: {
        marginTop: 70
    },
    date: {
        textAlign: 'right'
    }
});

const EndRow = () => {
    let options = {year: 'numeric', month: 'long', day: 'numeric'}
    const currentDate = new Intl.DateTimeFormat('hu-HU', options).format(Date.now())

    return (
        <View style={styles.section}>
            <Text style={styles.date}>{currentDate}</Text>   
        </View>
    )
}

export default EndRow