import React from 'react'
import { Routes, Route } from 'react-router-dom'
import LoggedOutLayout from './components/layout/LoggedOutLayout'
import BasicLayout from './components/layout/BasicLayout'
import Login from './tools/login/Login';
import Welcome from './tools/welcome/Welcome';
import Calculator from './tools/calculator/components/Calculator';
import { Provider } from 'react-redux'
import store from './redux/store'

function App() {
  return (
    <Provider store={store}>
      <Routes>
        <Route path="/" element={<LoggedOutLayout />}>
          <Route index element={<Login />} />
          <Route path="fe421aede59ae06fa679c114cf81995ca8819eee/tools" element={<BasicLayout />}>
            <Route index element={<Welcome />} />
            <Route path="calculator" element={<Calculator />} />
          </Route>
        </Route>
      </Routes>
    </Provider>
  );
}

export default App;
