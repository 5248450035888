import React from 'react'
import { getInstallationsByQuery } from '../redux/installationSlice'
import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Pending from './Pending'

let timer

const SearchForm = () => {
    const dispatch = useDispatch()
    const { queryList, offerList, pending, version } = useSelector((state) => state.calculator)
    const [query, setQuery] = useState('');
    const queryRef = useRef(null)

    const handleQueryChange = (obj) => {
        if(timer)
            clearTimeout(timer)

        timer = setTimeout(() => {
            setQuery(obj.value)
        },800)
    }

    useEffect(() => {
        if(version) {
            const model = offerList[version]['model']
            queryRef.current.value = `${model}/`
            queryRef.current.selectionStart = queryRef.current.value.length
            handleQueryChange(queryRef.current)
        }
    }, [])

    useEffect(() => {
        queryRef.current.focus()
    }, [queryList])

    useEffect(() => {
        if (!pending && query && query.length > 0)
            dispatch(getInstallationsByQuery(query))
    }, [query])

    return (
        <div className='search selector'>
            <label htmlFor="query">Model/code</label>
            <input type="text" name="query" id="query" onChange={(e) => handleQueryChange(e.target)} disabled={pending} ref={queryRef} />
            <Pending />
        </div>
    )
}

export default SearchForm