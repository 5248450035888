import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapLocationDot, faClock } from "@fortawesome/free-solid-svg-icons"
import { setAddress } from '../redux/installationSlice'
import Pending from './Pending'

const FormAddAddress = () => {
    const { pending } = useSelector((state) => state.calculator)
    const dispatch = useDispatch()
    const zipRef = useRef(null)
    const cityRef = useRef(null)
    const addressRef = useRef(null)
    const [addressParts, setAddressParts] = useState({
        zip: '',
        city: '',
        address: ''
    })
    const [error, setError] = useState(null)
    const [travelData, setTravelData] = useState(null)

    const saveAddress = () => {
        const zipValid = zipRef.current.reportValidity()
        const cityValid = cityRef.current.reportValidity()
        const addressValid = addressRef.current.reportValidity()

        zipRef.current.setAttribute('aria-invalid', !zipValid)
        cityRef.current.setAttribute('aria-invalid', !cityValid)
        addressRef.current.setAttribute('aria-invalid', !addressValid)
        
        if (zipValid && cityValid && addressValid) {
            dispatch(setAddress({...addressParts, setError, setTravelData }))
        }
    }

    const onChangeInput = (e) => {
        const name = e.target.name
        const value = e.target.value

        setAddressParts({...addressParts, [name]: value})
    }

    return (
        <div className='form-area address'>
            <input type="text" name="zip" id="zip" pattern="[0-9]{4}" required placeholder='zip code' ref={zipRef} onChange={(e) => onChangeInput(e)} />
            <input type="text" name="city" id="city" required placeholder='city' ref={cityRef} onChange={(e) => onChangeInput(e)} />
            <input type="text" name="address" id="address" required placeholder='address' ref={addressRef} onChange={(e) => onChangeInput(e)} />
            {error && (<div className='error'>{error}</div>)}
            {travelData && (<div className='travel'><FontAwesomeIcon icon={faMapLocationDot} /> {Math.ceil(travelData.distance / 1000)} km <FontAwesomeIcon icon={faClock} /> {Math.round((travelData.duration / 3600) * 10) / 10} óra</div>)}
            {!pending && (<div className="action"><button className='edit' onClick={() => saveAddress()}><FontAwesomeIcon icon={faMapLocationDot} /> Set</button></div>)}
            <Pending />
        </div>
    )
}

export default FormAddAddress