import React from 'react'
import { Text, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
    pagenumber: {
        fontSize: 12,
        lineHeight: 1,
        color: '#ffffff',
        position: 'absolute',
        bottom: -15,
        left: 0,
        right: 0,
        textAlign: 'center'
    }
  });

const PageNumber = () => {
  return (
    <Text style={styles.pagenumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />
  )
}

export default PageNumber