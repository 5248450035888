import React, { useEffect } from 'react'
import CalculatorOffer from './CalculatorOffer'
import CalculatorSelect from './CalculatorSelect'
import Modal from '../../../components/modal/Modal'
import FormEditOfferItem from './FormEditOfferItem'
import FormSelectSales from './FormSelectSales'
import FormAddAddress from './FormAddAddress'
import BasicInfo from './BasicInfo'
import PDFCreator from './PDFCreator'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserPlus, faCirclePlus, faMapLocationDot, faFilePdf } from "@fortawesome/free-solid-svg-icons"

import { useDispatch, useSelector } from 'react-redux'
import { setModal } from '../../../redux/modalSlice'
import { emptyInstallations, setVersion, setOption, getPrices, calculate } from '../redux/installationSlice'

import '../../../css/Layouts.css'
import '../css/Calculator.css'

const Calculator = () => {
  const { modal } = useSelector((state) => state)
  const { offerList, sales, version, option, price, address  } = useSelector((state) => state.calculator)
  const dispatch = useDispatch()

  const addInstallation = () => {
    dispatch(setModal('newInstallation'))
  }

  const selectSales = () => {
    dispatch(setModal('selectSale'))
  }

  const setAddress = () => {
    dispatch(setModal('setAddress'))
  }
  
  const savePdf = () => {
    dispatch(setModal('savePDF'))
  }

  useEffect(() => {
    dispatch(getPrices())
  }, [])

  //TODO: Ezt átrakni az App.js-be???
  useEffect(() => {
    if(!modal.state) {
      if (version) dispatch(setVersion(null))
      if (option) dispatch(setOption(null))
      dispatch(emptyInstallations())
    }
  }, [modal.state])

  useEffect(() => {
    if (Object.keys(offerList).length && price['labor fee'] && price['travel fee'] && address.duration && address.distance)
      dispatch(calculate({offerList, price, address}))
  }, [offerList, address])

  return (
    <>
      {['newInstallation', 'addOption'].includes(modal.state) && <Modal><CalculatorSelect /></Modal>}
      {['editOffer', 'editOption'].includes(modal.state) && <Modal><FormEditOfferItem /></Modal>}
      {['selectSale'].includes(modal.state) && <Modal><FormSelectSales /></Modal>}
      {['setAddress'].includes(modal.state) && <Modal><FormAddAddress /></Modal>}
      {['savePDF'].includes(modal.state) && <Modal><PDFCreator /></Modal>}
      <h1 className='page-title'>Calculator</h1>
      <div className="">
        <div className="calculator-actions action">
          <button onClick={() => addInstallation()} className="company"><FontAwesomeIcon icon={faCirclePlus} /> Add Installation</button>
          <button onClick={() => selectSales()} className="company"><FontAwesomeIcon icon={faUserPlus} /> Select Sales</button>
          <button onClick={() => setAddress()} className="company"><FontAwesomeIcon icon={faMapLocationDot} /> Add Address</button>
          {Object.keys(offerList).length > 0 && sales && address.distance && address.duration && (<button className="company" onClick={() => savePdf()}><FontAwesomeIcon icon={faFilePdf} /> Create PDF</button>)}
        </div>
        <BasicInfo />
        <CalculatorOffer />
      </div>
    </>
  )
}

export default Calculator