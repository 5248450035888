import React from 'react'
import { useSelector } from 'react-redux'
import { View, Text, StyleSheet } from '@react-pdf/renderer'
import { priceFormat } from '../../../../utility/tools';

const styles = StyleSheet.create({
    totalPrice: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        fontFamily: 'Raleway',
        fontSize: 14,
        fontWeight: 700,
        lineHeight: 1.4,
        marginBottom: 20,
        paddingBottom: 5,
        borderBottom: '1px solid #666666',
    },
    itemName: {
        width: '50%',
        textAlign: 'left'
    },
    total: {
        width: '50%',
        textAlign: 'right'
    }
});

const TotalPrice = () => {
    const { calculatedTotal } = useSelector((state) => state.calculator)

    return (
        <View style={styles.totalPrice}>
            <Text style={styles.itemName}>Végösszeg</Text>
            <Text style={styles.total}>
                {
                priceFormat(
                    {
                        price: calculatedTotal['Technikus']['Technikus teljes díjja'] 
                        + calculatedTotal['Segédtechnikus']['Segédtechnikus teljes díjja'] 
                        + calculatedTotal['Szoftvertechnikus']['Szoftvertechnikus teljes díjja'], 
                        lang: 'hu-HU', 
                        currency: 'HUF'
                        }
                )
                }</Text>
        </View>
    )
}

export default TotalPrice