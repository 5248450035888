import React from 'react'
import { View, Text, StyleSheet } from '@react-pdf/renderer'
import Option from './Option'

const styles = StyleSheet.create({
    installations: {
        width: '100%',
        fontFamily: 'Raleway',
        fontSize: 11,
        lineHeight: 1.4,
        paddingBottom: 10,
        marginBottom: 30,
        borderBottom: '1px solid #666666'
    },
    header: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        paddingTop: 5,
        paddingBottom: 5,
        marginBottom: 5,
        borderBottom: '1px solid #666666'
    },
    model: {
        width: '50%',
        fontSize: 12,
        fontWeight: 500,
        color: '#03505a'
    },
    code: {
        width: '50%',
        fontSize: 12,
        fontWeight: 500,
        textAlign: 'right'
    },
    options: {
        marginLeft: 30,
        marginTop: 10,
        paddingTop: 10,
    },
    h2: {
        fontWeight: 700,
        textTransform: 'uppercase',
        marginBottom: 10
    }
})

const Installation = ({installation}) => {
    return (
        <View style={styles.installations}>
            <View style={styles.header}>
                <Text style={styles.model}>{installation.model}</Text>
                <Text style={styles.code}>{installation.code}</Text>
            </View>
            <View>
                <Text style={styles.deescription}>{installation.description}</Text>
            </View>
            <View style={styles.options}>
                <Text style={styles.h2}>Opciók</Text>
                {Object.keys(installation.options).length > 0 ?
                    Object.keys(installation.options).map(optionKey => {
                        const option = installation.options[optionKey]

                        return <Option key={optionKey} option={option} />
                    })
                    : (<Text>-</Text>)
                }
            </View>
        </View>
    )
}

export default Installation