import React from 'react'
import { View, Text, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
    section: {
        marginTop: 70
    },
    title: {
        fontWeight: 700
    },
    paragraph: {
        marginBottom: 12
    },
    h1: {
        fontSize: 12,
        fontWeight: 700,
        marginBottom: 20
    }
});

const LegalText = () => {
  return (
    <View style={styles.section}>
        <Text style={styles.h1}>Beüzemelési feltételek</Text>   
        <Text style={styles.paragraph}>Többnapos beüzemelés esetén, ha a kiszállás ideje meghaladja a 3 órás utazást, a szállás költsége és foglalása a megrendelőt terheli.</Text>   
        <Text style={styles.paragraph}>Technikusaink munkaórái a legértékesebbek számunkra. Amennyiben a beüzemelést korábban tudják befejezni, úgy azt munkalap alapján jóváírjuk és a későbbiekben felhasználhatják supportra, szervizre, karbantartásra, tanácsadásra bármely SCM  gépük mellé.</Text>   
        <Text>Ugyanakkor, ha a gép érkezése előtt megküldött beüzemelési feltételeket aláírták és a szerviz időpontját visszaigazolták, mégsem voltak adottak a körülmények a munkálatok megkezdéséhez, úgy a többlet időt kiszámlázhatjuk, vagy a beüzemelést elhalaszthatjuk, ameddig a feltételek nem adottak a protokoll szerinti munkavégzéshez. Ebben az esetben a felesleges kiszállást szintén kiszámlázzuk. A beüzemelési feltételek gépenként változóak, amint a gyártó készre jelenti dokumentumait, csapatunk azonnal továbbítja magyar nyelven a megrendelő felé. </Text>
    </View>
  )
}

export default LegalText