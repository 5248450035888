import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalculator } from "@fortawesome/free-solid-svg-icons"
import "./BasicMainMenu.css"

const BasicMainMenu = () => {
  return (
    <div className="main-menu">
        <ul>
            <li>
                <Link to="calculator">
                    <span><FontAwesomeIcon icon={faCalculator} /></span>
                    ANEST Calculator
                </Link>
            </li>
        </ul>
    </div>
  )
}

export default BasicMainMenu