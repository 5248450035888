import React from 'react'
import { View, Text, Link, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
    companyData: {
        fontSize: 10,
        lineHeight: 1.4,
        flexDirection: 'column',
    },
    em: {
        fontWeight: 700
    },
    companyColor: {
        color: '#03505a'
    }
  });

const PageHeader = () => {
  return (
    <View style={styles.companyData}>
        <Text style={styles.em}>Anest Service by Service Team</Text>
        <Text style={styles.em}>Service Team Kft.</Text>
        <Text>2600 Vác (Václiget), Horgásztói u. 1.</Text>
        <Text>+36 27 345 292 / 106</Text>
        <Link src="mailto:szerviz@anest.hu"><Text style={styles.companyColor}>szerviz@anest.hu</Text></Link>
        <Link src="https://anest.hu"><Text style={styles.companyColor}>anest.hu</Text></Link>
        <Text>Adószám: 22981073-2-13</Text>
        <Text>MKB Bank Zrt. 10403253-50526575-69541008</Text>
    </View>
  )
}

export default PageHeader