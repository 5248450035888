import React from 'react'
import { View, Text, StyleSheet } from '@react-pdf/renderer'
import { useSelector } from 'react-redux'
import Installation from './Installation'

const styles = StyleSheet.create({
    installationsSection: {
        width: '100%',
        fontFamily: 'Raleway',
        fontSize: 11,
        lineHeight: 1.4,
        marginBottom: 20
    },
    h1: {
        fontSize: 12,
        fontWeight: 700,
        marginBottom: 20
    }
})

const InstallationList = () => {
    const { offerList } = useSelector((state) => state.calculator)

    return (
        <View style={styles.installationsSection} break>
            <Text style={styles.h1}>Beüzemelési lista</Text>
            {Object.keys(offerList).map(offerKey => {
                const offer = offerList[offerKey]

                return <Installation key={offerKey} installation={offer} />
            })}
        </View>
    )
}

export default InstallationList