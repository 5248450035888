import React from 'react'

import { useDispatch } from "react-redux"
import { setModal } from '../../redux/modalSlice'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons"
import './Modal.css'

const Modal = ({children}) => {
    const dispatch = useDispatch()

    const closeModal = () => {
        dispatch(setModal(null))
    }

    return (
        <>
        <div className="modal">
            <div className="overlay">
                <div className="modal-window">
                    <div className="close" onClick={() => closeModal()}><FontAwesomeIcon icon={faCircleXmark} /></div>
                    <div className="content">
                        {children}
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Modal