import React from 'react'
import { Outlet } from "react-router-dom"
import BasicHeader from "./BasicHeader"
import "./BasicLayout.css"

const BasicLayout = () => {
  return (
    <>
        <BasicHeader />
        <main>
            <Outlet />
        </main>
    </>
  )
}

export default BasicLayout