import React from 'react'
import { Provider } from 'react-redux'
import {PDFViewer} from '@react-pdf/renderer'
import DocumentPDFOffer from './PDF/DocumentPDFOffer'
import store from '../../../redux/store'

const PDFCreator = () => {
  return (
    <>
      
        <PDFViewer width="100%" height="600" className="pdf-viewer" >
          <Provider store={store}>
            <DocumentPDFOffer />
          </Provider>  
        </PDFViewer>
      
    </>
  )
}

export default PDFCreator