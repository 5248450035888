import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSales } from '../redux/installationSlice'

const FormSelectSales = () => {
    const { sales } = useSelector((state) => state.calculator)
    const dispatch = useDispatch()
    const [salesName, setSalesName] = useState(sales)
    const selectRef = useRef(null)

    useEffect(() => {
        selectRef.current.value = sales
    }, [])
    
    useEffect(() => {
        dispatch(setSales(salesName))
    }, [salesName])

    return (
        <div className='form-area selector'>
            <select name="sale" id="sale" onChange={(e) => setSalesName(e.target.value)} ref={selectRef} >
                <option value="null">Select Sales</option>
                <option value="Eősze Sándor">Eősze Sándor</option>
                <option value="Kiss Csaba">Kiss Csaba</option>
                <option value="Szarka László">Szarka László</option>
                <option value="Tatár Zsolt">Tatár Zsolt</option>
            </select>
        </div>
    )
}

export default FormSelectSales